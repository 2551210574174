import { FC } from "react";
import {
  buildersBlue,
  curvyArrow,
  dashBlue,
  dashPink,
  engage,
  instagram,
  iPerson,
  linkedIn,
  logoPinkBig,
  plug,
  xwitter,
  dashPinkMedium,
} from "../../common/svg";

const Contact: FC = () => {
  return (
    <div className="contact relative">
      <div className="contact bg-primary-200 py-16 px-24 w-full">
        <div className="contact-nav flex flex-col items-center">
          <h4 className="font-roxbor font-semibold text-[24px] md:text-[28.5px] text-grey-400 ">
            Here's What You Can Do Now
          </h4>
          <span
            className="-mt-1 hidden md:block"
            dangerouslySetInnerHTML={{ __html: dashBlue }}
          />

          <span
            className="-mt-1 block md:hidden "
            dangerouslySetInnerHTML={{ __html: dashPinkMedium }}
          />
        </div>

        <div className="contact-card flex items-center w-full justify-center gap-x-4 pt-12 pb-3">
          <div className="can-card py-10 px-5 bg-white rounded-[5px] w-[20rem] flex flex-col items-start gap-y-6">
            <span
              className="-mt-1"
              dangerouslySetInnerHTML={{ __html: plug }}
            />

            <div className="font-inter text-grey-400">
              <h5 className="font-semibold text-[15px]">
                Connect with Us on Socials
              </h5>
              <p className="font-medium text-[11.5px] mt-1">
                Stay connected and get the latest updates by following us on
                social media.
              </p>
            </div>
            <div className="flex items-end justify-start py-[8px] w-[110px] gap-x-2.5">
              <a
                href="https://www.linkedin.com/company/builderstribe/posts/?feedView=all"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className=" hover:border hover:border-gray-400 hover:rounded-full"
                  dangerouslySetInnerHTML={{ __html: linkedIn }}
                />
              </a>

              <a
                href="https://www.instagram.com/builderstribehq?igsh=MWQ5eGtnM3BpZTZkbw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  className=" hover:border hover:border-gray-400 hover:rounded-full"
                  dangerouslySetInnerHTML={{ __html: instagram }}
                />
              </a>

              <a
                href="https://x.com/builderstribehq"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <span
                  className="cursor-pointer hover:border hover:border-gray-400 hover:rounded-full"
                  dangerouslySetInnerHTML={{ __html: xwitter }}
                />
              </a>
            </div>
          </div>

          <div className="can-card py-10 px-5 bg-white rounded-[5px] w-[20rem] flex flex-col items-start gap-y-6">
            <span
              className="-mt-1"
              dangerouslySetInnerHTML={{ __html: engage }}
            />

            <div className="font-inter text-grey-400">
              <h5 className="font-semibold text-[15px]">Engage with Us</h5>
              <p className="font-medium text-[11.5px] mt-1">
                Join our active WhatsApp community to connect, share insights,
                and collaborate with members.
              </p>
            </div>
            <button
              type="button"
              className="font-medium text-[12.5px] py-[9px] px-4 bg-primary-400 hover:bg-primary-500 text-white rounded-[10px] w-[110px]"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSd8C998ZzpsoGTo5mbCl8gOXsdVLhX035V0_OflwrQmprx2hg/viewform",
                  "_blank"
                )
              }
            >
              Join Us
            </button>
          </div>

          <div className="can-card can-card1 py-10 px-5 bg-white rounded-tr-[150px] rounded-tl-[5px] rounded-b-[5px] w-[20rem] flex flex-col items-start gap-y-6">
            <span
              className="-mt-1"
              dangerouslySetInnerHTML={{ __html: iPerson }}
            />

            <div className="font-inter text-grey-400">
              <h5 className="font-semibold text-[15px] ">
                Become a Community Leader
              </h5>
              <p className="font-medium text-[11.5px] mt-1">
                Our community leaders are here to enhance your experience. Apply
                to become a leader
              </p>
            </div>
            <button
              type="button"
              className="font-medium text-[12.5px] py-[9px] px-4 bg-primary-400 hover:bg-primary-500 text-white rounded-[10px] w-[110px]"
              onClick={() =>
                window.open("https://forms.gle/P7M4UkXoASYVfEGWA", "_blank")
              }
            >
              Get in Touch
            </button>
          </div>
        </div>
      </div>

      <div
        id="upcoming-activities"
        className="ready-for w-full hidden md:block"
      >
        <img
          src="/assets/ready-bg.svg"
          alt="Here's what we're getting ready for"
        />
      </div>

      {/* ======================
upcoming-activities
======================= */}

      <div id="upcoming-activities" className="ready-forMD block md:hidden">
        <div className="contact-nav flex flex-col items-center">
          <h4 className="font-roxbor font-semibold text-[24px] md:text-[28.5px] text-grey-400 text-center ">
            Here’s What We Are Getting Ready for You
          </h4>
          <span
            className="-mt-1 hidden md:block"
            dangerouslySetInnerHTML={{ __html: dashPink }}
          />
          <span
            className="-mt-1 block md:hidden "
            dangerouslySetInnerHTML={{ __html: dashPinkMedium }}
          />
        </div>

        <div className="upcomaing-container">
          <div className="investment-wrapper rounded-[12px] bg-[#6173FF] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#ffffff] mb-2">
              Investment Opportunities
            </h4>
            <p className="font-normal text-[16px] text-[#ffffff] ">
              Explore upcoming opportunities to connect with potential investors
              and secure funding for your startup.
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#A49F2D] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#ffffff] mb-2">
              Exclusive Content
            </h4>
            <p className=" invest-text font-normal text-[16px] text-[#ffffff] ">
              Access a wealth of resources, including founder stories, industry
              insights, and practical guides to help you succeed in the tech
              industry.
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#9CB8FF] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#04244E] mb-2">
              Mentorship Program
            </h4>
            <p className="font-normal text-[16px] text-[#04244E] ">
              We will soon connect you with experienced professionals who can
              provide guidance and support on your journey.
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#444CF7] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#ffffff] mb-2">
              Annual Tech Conference
            </h4>
            <p className="font-normal text-[16px] text-[#ffffff] ">
              Our annual conference will bring together the brightest minds in
              tech for a day of learning, networking, and innovation.
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#F743A6] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#ffffff] mb-2">
              Quaterly Regional Events
            </h4>
            <p className="font-normal text-[16px] text-[#ffffff] ">
              Participate in our upcomingquarterly events, engage and learn from
              industry leaders.
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#F9F482] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#04244E] mb-2">
              Monthly Local Meetups
            </h4>
            <p className="font-normal text-[16px] text-[#04244E] ">
              Our monthly meetups in major cities across the MENA region will
              connect with fellow tech enthusiasts
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#C1D4FF] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#04244E] mb-2">
              Upcoming Events
            </h4>
            <p className="font-normal text-[16px] text-[#04244E] ">
              Stay tuned for our latest events and workshops designed to foster
              collaboration and growth
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#F743A6] h-[152px] p-[20px] ">
            <h4 className="font-semibold text-[18px] text-[#ffffff] mb-2">
              Blog
            </h4>
            <p className="font-normal text-[16px] text-[#ffffff] ">
              Stay informed with the latest trends from the tech industry,
              expert opinions and community updates.
            </p>
          </div>
          <div className="investment-wrapper rounded-[12px] bg-[#F6EF43] h-[152px] p-[12px]   ">
            <h4 className="font-semibold text-[18px] text-[#04244E] mb-2">
              Learning Hub
            </h4>
            <p className=" font-normal text-[16px] text-[#04244E]">
              Enhance your skills with our curated selection of courses,
              webinars, and workshops designed to help you grow.
            </p>
          </div>
        </div>
      </div>

      {/* ======================
upcoming-activities
======================= */}

      <div className="join-us w-full pt-8 pb-16 px-24 bg-secondary-500 flex flex-col items-center">
        <span
          className="self-center md:self-end hidden md:block"
          dangerouslySetInnerHTML={{ __html: buildersBlue }}
        />

        <div className="arrow-join flex items-center gap-x-4">
          <span
            className=" hidden md:block"
            dangerouslySetInnerHTML={{ __html: logoPinkBig }}
          />
          <h2 className="-mt-3.5 font-roxbor font-normal text-[32px] md:text-[90px] text-secondary-400">
            Join Us today!
          </h2>
        </div>

        <div className="flex flex-col items-center -mt-4">
          <span
            className="z-20"
            dangerouslySetInnerHTML={{ __html: curvyArrow }}
          />
          <button
            className="-mt-2 member-btn z-10 text-white font-inter font-normal text-[14px] bg-[#1C89EE] py-3.5 px-6 rounded-[10px] hover:bg-primary-500"
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSd8C998ZzpsoGTo5mbCl8gOXsdVLhX035V0_OflwrQmprx2hg/viewform",
                "_blank"
              )
            }
          >
            Become a member for free
          </button>
          <span
            className="self-center mt-6 block md:hidden"
            dangerouslySetInnerHTML={{ __html: buildersBlue }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
